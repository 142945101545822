import { memo, Fragment } from "react";
import image from "../../../assets/images/Logo.png";
import image2 from "../../../assets/images/Collapse Logo.png";
const Logo = memo((props) => {
  return (
    <Fragment>
      <div className="logo-main">
        <div className="logo-normal ms-3">
          <img width="135px" src={image} alt="" />
        </div>
        <div className="logo-mini me-3">
          <img src={image2} width='45px' alt="" />
        </div>
      </div>
    </Fragment>
  );
});

Logo.displayName = "Logo";
export default Logo;
